import { useState, useEffect, useContext } from 'react';
import { useEventListener } from '../utils/customHooks';
import axios from 'axios';
import '../assets/css/Settings.css';
import { useNavigate } from 'react-router-dom';
import authContext from '../utils/authContext';

function ConfigureDefaultPackageTab() {
    const [advancedThreatControl, setAdvancedThreatControl] = useState(false);
    const [advancedAntiExploit, setAdvancedAntiExploit] = useState(false);
    const [deviceControl, setDeviceControl] = useState(false);
    const [edrSensor, setEdrSensor] = useState(false);
    const [encryption, setEncryption] = useState(false);
    const [firewall, setFirewall] = useState(false);
    const [contentControl, setContentControl] = useState(false);
    const [networkAttackDefense, setNetworkAttackDefense] = useState(false);
    const [powerUser, setPowerUser] = useState(false);
    const [patchManagement, setPatchManagement] = useState(false);
    const [exchange, setExchange] = useState(false);
    const [removeCompetitors, setRemoveCompetitors] = useState(false);
    const [uninstallPassword, setUninstallPassword] = useState('');
    const [confirmUninstallPassword, setConfirmUninstallPassword] = useState('');
    const [initialUninstallPassword, setInitialUninstallPassword] = useState('');
    const [language, setLanguage] = useState('');
    const [isLoading, setIsLoading] = useState(false); //button loading
    const [loading, setLoading] = useState(true); //screen loading

    const { authenticated } = useContext(authContext);

    const navigate = useNavigate();

    const [errors, setErrors] = ([]);

    const handleSwitchChange = e => {
        switch (e.target.id) {
            case 'advancedThreatControl':
                setAdvancedThreatControl(e.detail.checked);
                break;

            case 'advancedAntiExploit':
                setAdvancedAntiExploit(e.detail.checked);
                break;

            case 'deviceControl':
                setDeviceControl(e.detail.checked);
                break;

            case 'edrSensor':
                setEdrSensor(e.detail.checked);
                break;

            case 'encryption':
                setEncryption(e.detail.checked);
                break;

            case 'firewall':
                setFirewall(e.detail.checked);
                break;

            case 'contentControl':
                setContentControl(e.detail.checked);
                break;

            case 'networkAttackDefense':
                setNetworkAttackDefense(e.detail.checked);
                break;

            case 'powerUser':
                setPowerUser(e.detail.checked);
                break;

            case 'patchManagement':
                setPatchManagement(e.detail.checked);
                break;

            case 'exchange':
                setExchange(e.detail.checked);
                break;

            case 'removeCompetitors':
                setRemoveCompetitors(e.detail.checked);
                break;
            default:
                setErrors([...errors, 'There is an error!'])
        }
    }

    const handlePasswordChange = e => {
        if (e.target.id === 'uninstallPassword') {
            setUninstallPassword(e.target.value);
        } else {
            setConfirmUninstallPassword(e.target.value);
        }
    }

    const handleSelect = e => {
        setLanguage(e.detail.newValue.value);
    }

    const getPackageConfiguration = async () => {
        setLoading(true);
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/api/packages/default',
                withCredentials: true
            });

            setAdvancedThreatControl(response.data.advancedThreatControl);
            setAdvancedAntiExploit(response.data.advancedAntiExploit);
            setDeviceControl(response.data.deviceControl);
            setEdrSensor(response.data.edrSensor);
            setEncryption(response.data.encryption);
            setFirewall(response.data.firewall);
            setContentControl(response.data.contentControl);
            setNetworkAttackDefense(response.data.networkAttackDefense);
            setPowerUser(response.data.powerUser);
            setPatchManagement(response.data.patchManagement);
            setExchange(response.data.exchange);
            setRemoveCompetitors(response.data.removeCompetitors);
            setLanguage(response.data.language);
            setInitialUninstallPassword(response.data.uninstallPassword);
            if (response.data.uninstallPassword !== null && response.data.uninstallPassword !== '') {
                setUninstallPassword('********');
                setConfirmUninstallPassword('********');
            } else {
                setUninstallPassword(response.data.uninstallPassword);
                setConfirmUninstallPassword(response.data.uninstallPassword);
            }
        } catch (error) {
            setErrors({ receiveDataError: error.response.data });
        }
        setLoading(false);
    }

    const verifyPassword = () => {
        if (uninstallPassword === '' && confirmUninstallPassword === '') {
            return true;
        }
        if (uninstallPassword === null && confirmUninstallPassword === null) {
            return true;
        }
        if ((uninstallPassword === '' || uninstallPassword === null) && confirmUninstallPassword !== '') {
            document.getElementById('confirmUninstallPassword').markAsInvalid();
            return false;
        }
        if (uninstallPassword !== '' && (confirmUninstallPassword === '' || confirmUninstallPassword === null)) {
            document.getElementById('confirmUninstallPassword').markAsInvalid();
            return false;
        }
        if (uninstallPassword !== '********' && confirmUninstallPassword !== '********') {
            if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(uninstallPassword))) {
                document.getElementById('uninstallPassword').markAsInvalid();
                return false;
            }

            if (confirmUninstallPassword !== null) {
                if (uninstallPassword !== confirmUninstallPassword) {
                    document.getElementById('confirmUninstallPassword').markAsInvalid();
                    return false;
                }
            }
        }

        if (uninstallPassword !== '********' && confirmUninstallPassword === '********') {
            document.getElementById('confirmUninstallPassword').markAsInvalid();
            return false;
        }

        if (uninstallPassword === '********' && confirmUninstallPassword !== '********') {
            document.getElementById('confirmUninstallPassword').markAsInvalid();
            return false;
        }
        return true;
    }

    const toastNotification = (message, severity) => {
        const toastNotificationConfigs = {
            message: message,
            severity: severity,
            x: 'right',
            y: 'bottom',
        };

        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }

    var requestBody = {
        'advancedThreatControl': advancedThreatControl,
        'advancedAntiExploit': advancedAntiExploit,
        'deviceControl': deviceControl,
        'edrSensor': edrSensor,
        'encryption': encryption,
        'firewall': firewall,
        'contentControl': contentControl,
        'networkAttackDefense': networkAttackDefense,
        'powerUser': powerUser,
        'patchManagement': patchManagement,
        'exchange': exchange,
        'removeCompetitors': removeCompetitors,
        'uninstallPassword': uninstallPassword === '********' ? initialUninstallPassword : uninstallPassword,
        'language': language
    };

    const savePackageConfiguration = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (verifyPassword()) {
            axios({
                method: 'put',
                url: process.env.REACT_APP_API_URL + '/api/packages/default',
                data: requestBody,
                withCredentials: true
            })
                .then(() => {
                    if (!authenticated) {
                        toastNotification('Package successfully saved.', 'success');
                        navigate('/inventory');
                    } else {
                        toastNotification('Changes successfully saved.', 'success');
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    toastNotification('Something went wrong! Please try again later!', 'error');
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPackageConfiguration();
    }, []);

    useEventListener('change', handleSwitchChange, document.getElementById('advancedThreatControl'));
    useEventListener('change', handleSwitchChange, document.getElementById('advancedAntiExploit'));
    useEventListener('change', handleSwitchChange, document.getElementById('deviceControl'));
    useEventListener('change', handleSwitchChange, document.getElementById('edrSensor'));
    useEventListener('change', handleSwitchChange, document.getElementById('encryption'));
    useEventListener('change', handleSwitchChange, document.getElementById('firewall'));
    useEventListener('change', handleSwitchChange, document.getElementById('contentControl'));
    useEventListener('change', handleSwitchChange, document.getElementById('networkAttackDefense'));
    useEventListener('change', handleSwitchChange, document.getElementById('powerUser'));
    useEventListener('change', handleSwitchChange, document.getElementById('patchManagement'));
    useEventListener('change', handleSwitchChange, document.getElementById('exchange'));
    useEventListener('change', handleSwitchChange, document.getElementById('removeCompetitors'));

    useEventListener('change', handlePasswordChange, document.getElementById('uninstallPassword'));
    useEventListener('change', handlePasswordChange, document.getElementById('confirmUninstallPassword'));
    useEventListener('optionSelected', handleSelect, document.getElementById('language'));

    useEventListener('click', savePackageConfiguration, document.getElementById('save-configure-package-btn'));

    const options = [
        {
            id: 0,
            title: 'English',
            value: 'en_US',
        },
        {
            id: 1,
            title: 'Czech',
            value: 'cz_CZ',
        },
        {
            id: 2,
            title: 'German',
            value: 'de_DE',
        },
        {
            id: 3,
            title: 'Spanish',
            value: 'es_ES',
        },
        {
            id: 4,
            title: 'French',
            value: 'fr_FR',
        },
        {
            id: 5,
            title: 'Italian',
            value: 'it_IT',
        },
        {
            id: 6,
            title: 'Polish',
            value: 'pl_PL',
        },
        {
            id: 7,
            title: 'Brazilian',
            value: 'pt_BR',
        },
        {
            id: 8,
            title: 'Romanian',
            value: 'ro_RO',
        },
        {
            id: 9,
            title: 'Russian',
            value: 'ru_RU',
        }
    ];

    return (
        <div style={{ overflowY: 'scroll', maxHeight: '90vh' }}>
            <div className='p-4 mb-3'>
                <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>Default Package</gz-title>
                <div className='center-div' style={{ visibility: loading === true? 'visible': 'hidden'}}>
                    <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
                </div>
                <div style={{ visibility: loading === true? 'hidden': 'visible'}}>
                    <div className='mt-4 mb-5'>
                        <gz-text fontsize="13" lineheight="20">A new deployment package will be created in GravityZone for each client using the following default settings. You will be able to modify each of these packages in GravityZone Control Center.</gz-text>
                    </div>
                    <div className='mb-5'>
                        <gz-title fontsize="18" fontweight="500">Settings</gz-title>
                        <div className='mt-3 mb-5'>
                            <gz-form-field label="Language" id="languageField">
                                <gz-dropdown
                                    gz-form-input
                                    options={JSON.stringify(options)}
                                    labelwidth="240"
                                    itemsvisible="10"
                                    localfiltering
                                    noresultsfound="No results found"
                                    value={language}
                                    listposition="bottom"
                                    id='language'>
                                </gz-dropdown>
                            </gz-form-field>
                        </div>
                        <div>
                            <div className='mb-3'>
                                <gz-title fontsize="11" fontweight='600' style={{ opacity: '0.5' }}>SET UNINSTALL PASSWORD</gz-title>
                            </div>
                            <gz-form-field label="Password" id="uninstallPasswordField">
                                <gz-input
                                    gz-form-input
                                    type="password"
                                    errortooltiptext='The password must contain at least 6 characters and comply with the following complexity: at least one digit, one upper case, one lower case and one special character.'
                                    maxchars="100"
                                    value={uninstallPassword}
                                    inputwidth="240"
                                    id='uninstallPassword' >
                                </gz-input>
                            </gz-form-field>
                            <gz-form-field label="Confirm Password" id="confirmUninstallPasswordField">
                                <gz-input
                                    gz-form-input
                                    type="password"
                                    errortooltiptext='Passwords are not the same'
                                    maxchars="100"
                                    value={confirmUninstallPassword}
                                    inputwidth="240"
                                    id='confirmUninstallPassword' >
                                </gz-input>
                            </gz-form-field>
                        </div>
                    </div>
                    <div className='mt-3 mb-5'>
                        <div className='mb-3'>
                            <gz-title fontsize='18' fontweight='500'>Modules</gz-title>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label=" Advanced Threat Control"
                                value="advancedThreatControl"
                                id='advancedThreatControl'
                                {...(advancedThreatControl ? { checked: true } : {})}
                                {...(advancedThreatControl === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label=" Advanced Anti-Exploit"
                                value="advancedAntiExploit"
                                id='advancedAntiExploit'
                                {...(advancedAntiExploit ? { checked: true } : {})}
                                {...(advancedAntiExploit === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Device Control"
                                value="deviceControl"
                                id='deviceControl'
                                {...(deviceControl ? { checked: true } : {})}
                                {...(deviceControl === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="EDR Sensor"
                                value="edrSensor"
                                id='edrSensor'
                                {...(edrSensor ? { checked: true } : {})}
                                {...(edrSensor === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Encryption"
                                value="encryption"
                                id='encryption'
                                {...(encryption ? { checked: true } : {})}
                                {...(encryption === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Firewall"
                                value="firewall"
                                id='firewall'
                                {...(firewall ? { checked: true } : {})}
                                {...(firewall === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Content Control"
                                value="contentControl"
                                id='contentControl'
                                {...(contentControl ? { checked: true } : {})}
                                {...(contentControl === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Network Attack Defense"
                                value="networkAttackDefense"
                                id='networkAttackDefense'
                                {...(networkAttackDefense ? { checked: true } : {})}
                                {...(networkAttackDefense === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Power User"
                                value="powerUser"
                                id='powerUser'
                                {...(powerUser ? { checked: true } : {})}
                                {...(powerUser === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Patch Management"
                                value="patchManagement"
                                id='patchManagement'
                                {...(patchManagement ? { checked: true } : {})}
                                {...(patchManagement === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Exchange Protection"
                                value="exchange"
                                id='exchange'
                                {...(exchange ? { checked: true } : {})}
                                {...(exchange === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                        <div className='mb-3'>
                            <gz-checkbox
                                gz-form-input
                                errortooltiptext
                                color="primary"
                                label="Remove Competitors"
                                value="removeCompetitors"
                                id='removeCompetitors'
                                {...(removeCompetitors ? { checked: true } : {})}
                                {...(removeCompetitors === null ? { disabled: true } : {})}>
                            </gz-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div className='page-footer fixed-bottom-navbar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-3 navbar-light nav-bottom-shadow py-3' style={{ visibility: loading === true ? 'hidden' : 'visible' }}>
                        <gz-button type="full" color="primary" buttonheight="medium" buttonfontsize="medium"
                            id='save-configure-package-btn' {...(isLoading === true ? { disabled: true } : {})}>
                            SAVE CHANGES
                        </gz-button>
                        </div>
                        <div className='col-sm-7 nav-bottom-shadow py-2' >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfigureDefaultPackageTab;
