import { useEffect, useState, useContext } from 'react';
import { useEventListener } from '../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import authContext from '../utils/authContext';
import axios from 'axios';
import '../assets/css/Settings.css';

const initialState = {
    gzApiKey: '',
    gzServerAddress: '',
    rmmApiKey: '',
    rmmApiSecret: '',
    serverAddress: ''
};

const dattoUrlSuffix = 'centrastage\.net';

function SettingsTab() {
    const [gzApiKey, setGzApiKey] = useState('');
    const [gzServerAddress, setGzServerAddress] = useState('');
    const [serverAddress, setServerAddress] = useState('');
    const [rmmApiKey, setRmmApiKey] = useState('');
    const [rmmApiSecret, setRmmApiSecret] = useState('');
    const [changedValues, setChangedValues] = useState({
        gzApiKey: null,
        gzServerAddress: null,
        rmmApiKey: null,
        rmmApiSecret: null,
        serverAddress: null
    });
    const [errors, setErrors] = useState(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const [tenantStatus, setTenantStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false); //button loading
    const [loading, setLoading] = useState(true); //screen loading
    const [disabledButton, setDisabledButton] = useState(false);
    const [canApiURLBeChanged, setCanApiURLBeChanged] = useState(false);

    const { authenticated, setAuthenticated } = useContext(authContext);

    const navigate = useNavigate();

    const dialogBody = document.createElement('div');
    dialogBody.innerHTML =
        `<p>Since this is your first time here, we would like to help you set up your environment properly.</p>
    <br/>
    <p>Make sure to fill in the required information on the <b>Integration</b> and <b>Default Packages</b> pages.</p>`;

    const invalidDialogBody = document.createElement('div');
    invalidDialogBody.innerHTML =
        `<p>Since your last time here, your credentials have become invalid or do not exist anymore.</p>
        <br/>
        <p>To use the Datto integration, add new credentials in the missing fields.</p>`;


    const footer = document.createElement('div');
    footer.innerHTML = `<gz-button id="confirmButton">OK</gz-button>`;

    footer.querySelector('#confirmButton').addEventListener('click', () => {
        closeAfterSomeEvent();
    });

    const closeAfterSomeEvent = () => {
        setIsOpen(false);
        dialog.dismiss();
    }

    const dialog = window.nirvana.dependencies.services.buildDialogElement(
        {
            headline: tenantStatus === 'New Tenant' ? "Welcome!" : 'Invalid integration credentials!',
            bodyContent: tenantStatus === 'New Tenant' ? dialogBody : invalidDialogBody,
            isOpen: isOpen,
            footerContent: footer,
            onCloseHandler: closeAfterSomeEvent,
        },
        document.querySelector('#main-div')
    );

    const getCredentials = async () => {
        setLoading(true);
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
                withCredentials: true
            });
            setGzApiKey(response.data.gzApiKeyMasked);
            setGzServerAddress(response.data.gzServerAddress);
            setRmmApiKey(response.data.rmmApiKey);
            setServerAddress(response.data.serverAddress);
        } catch (error) {
            setErrors({ receiveDataError: error.response.data });
        }
        setLoading(false);
    }

    const handleServerAddressChange = (e) => {
        setServerAddress(e.target.value);
        let items = changedValues;
        items.serverAddress = e.target.value;
        setChangedValues(items);
    }

    const handleGzApiKeyChange = (e) => {
        setGzApiKey(e.target.value);
        let items = changedValues;
        items.gzApiKey = e.target.value;
        setChangedValues(items);
    }

    const handleGzServerAddressChange = (e) => {
        setGzServerAddress(e.target.value);
        let items = changedValues;
        items.gzServerAddress = e.target.value;
        setChangedValues(items);
    }

    const handleRmmApiKeyChange = (e) => {
        setRmmApiKey(e.target.value);
        let items = changedValues;
        items.rmmApiKey = e.target.value;
        setChangedValues(items);
    }

    const handleRmmApiSecretChange = (e) => {
        setRmmApiSecret(e.target.value);
        let items = changedValues;
        items.rmmApiSecret = e.target.value;
        setChangedValues(items);
    }

    const toastNotification = (message, severity) => {
        const toastNotificationConfigs = {
            message: message,
            severity: severity,
            x: 'right',
            y: 'bottom',
            autoCloseDelayMs: 10000,
        };

        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }

    const validateForm = () => {
        let temp = {};

        if (changedValues.gzApiKey !== null) {
            temp.gzApiKey = changedValues.gzApiKey.length !== 0 ? '' : 'GravityZone API Key';
        }
        if (changedValues.gzServerAddress !== null) {
            temp.gzServerAddress = /((https):\/\/)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(changedValues.gzServerAddress) ? '' : 'GravityZone Access URL';
        }
        if (changedValues.rmmApiKey !== null) {
            temp.rmmApiKey = changedValues.rmmApiKey.length !== 0 ? '' : 'Datto RMM API Key';
        }
        if (changedValues.rmmApiSecret !== null && changedValues.rmmApiSecret !== rmmApiSecret) {
            temp.rmmApiSecret = changedValues.rmmApiSecret.length !== 0 ? '' : 'Datto RMM API Secret Key';
        }
        if (changedValues.serverAddress !== null) {
            temp.serverAddress = RegExp('((https):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.' + dattoUrlSuffix + '$').test(changedValues.serverAddress) ? '' : 'Datto RMM API URL';
        }

        inputError(temp);

        return Object.values(temp).every(x => x === '');
    }

    const validateRegister = () => {
        let temp = {};

        if (changedValues.gzApiKey !== null) {
            temp.gzApiKey = changedValues.gzApiKey.length !== 0 ? '' : 'GravityZone API Key';
        } else {
            temp.gzApiKey = 'GravityZone API Key';
        }
        if (changedValues.gzServerAddress !== null) {
            temp.gzServerAddress = /((https):\/\/)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(changedValues.gzServerAddress) ? '' : 'GravityZone Access URL';
        } else {
            temp.gzServerAddress = 'GravityZone Access URL';
        }
        if (changedValues.rmmApiKey !== null) {
            temp.rmmApiKey = changedValues.rmmApiKey.length !== 0 ? '' : 'Datto RMM API Key';
        } else {
            temp.rmmApiKey = 'Datto RMM API Key';
        }
        if (changedValues.rmmApiSecret !== null) {
            temp.rmmApiSecret = changedValues.rmmApiSecret.length !== 0 ? '' : 'Datto RMM API Secret Key';
        } else {
            temp.rmmApiSecret = 'Datto RMM API Secret Key';
        }
        if (changedValues.serverAddress !== null) {
            temp.serverAddress = RegExp('((https):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.' + dattoUrlSuffix + '$').test(changedValues.serverAddress) ? '' : 'Datto RMM API URL';
        } else {
            temp.serverAddress = 'Datto RMM API URL';
        }

        inputError(temp);

        return Object.values(temp).every(x => x === '');
    }

    const inputError = (temp) => {
        if (temp.hasOwnProperty('gzApiKey')) {
            if (temp.gzApiKey !== '') {
                document.getElementById('gzApiKey').markAsInvalid();
                setIsLoading(false);
            } else {
                document.getElementById('gzApiKey').markAsValid();
            }
        }
        if (temp.hasOwnProperty('gzServerAddress')) {
            if (temp.gzServerAddress !== '') {
                document.getElementById('gzServerAddress').markAsInvalid();
                setIsLoading(false);
            } else {
                document.getElementById('gzServerAddress').markAsValid();
            }
        }
        if (temp.hasOwnProperty('rmmApiKey')) {
            if (temp.rmmApiKey !== '') {
                document.getElementById('rmmApiKey').markAsInvalid();
                setIsLoading(false);
            } else {
                document.getElementById('rmmApiKey').markAsValid();
            }
        }
        if (temp.hasOwnProperty('rmmApiSecret')) {
            if (temp.rmmApiSecret !== '') {
                document.getElementById('rmmApiSecret').markAsInvalid();
                setIsLoading(false);
            } else {
                document.getElementById('rmmApiSecret').markAsValid();
            }
        }
        if (temp.hasOwnProperty('serverAddress')) {
            if (temp.serverAddress !== '') {
                document.getElementById('serverAddress').markAsInvalid();
                setIsLoading(false);
            } else {
                document.getElementById('serverAddress').markAsValid();
            }
        }
    }

    const saveData = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!authenticated && tenantStatus === 'New Tenant') {
            if (validateRegister()) {
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + '/api/tenant',
                    data: changedValues,
                    withCredentials: true
                })
                    .then(() => {
                        toastNotification('Credentials successfully saved.', 'success');
                        setIsLoading(false);
                        navigate('/defaultpackage');
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 500 || error.response.status === 409) {
                                toastNotification('The server encountered an unexpected error.', 'error');
                            } else {
                                toastNotification(error.response.data, 'error');
                            }
                        }
                        setIsLoading(false);
                    });
            }
        } else if (!authenticated && tenantStatus === 'Reset Tenant') {
            if (validateRegister()) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_URL + '/api/tenant',
                    data: changedValues,
                    withCredentials: true
                })
                    .then(() => {
                        toastNotification('Changes successfully saved.', 'success');
                        setIsLoading(false);
                        navigate('/defaultpackage');
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 500 || error.response.status === 409) {
                                toastNotification('The server encountered an unexpected error.', 'error');
                            } else {
                                toastNotification(error.response.data, 'error');
                            }
                        }
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        } else {
            if (validateForm()) {
                axios({
                    method: 'patch',
                    url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
                    data: changedValues,
                    withCredentials: true
                })
                    .then(() => {
                        toastNotification('Changes successfully saved.', 'success');
                        setIsLoading(false);
                        if (tenantStatus === 'Tenant With Invalid Credentials') {
                            navigate('/companies');
                            setAuthenticated(true);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 500) {
                            toastNotification('The server encountered an unexpected error.', 'error');
                        } else {
                            toastNotification(error.response.data, 'error');
                        }
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        }
        setChangedValues({
            gzApiKey: null,
            gzServerAddress: null,
            rmmApiKey: null,
            rmmApiSecret: null,
            serverAddress: null
        });
    }

    useEffect(() => {
        if (isLoading === true && Object.values(changedValues).every(x => x === null || x === '') === true) {
            setDisabledButton(true);
        } else if (isLoading === false && Object.values(changedValues).every(x => x === null || x === '') === true) {
            setDisabledButton(true);
        } else if (isLoading === true && Object.values(changedValues).every(x => x === null || x === '') === false) {
            setDisabledButton(true);
        } else if (isLoading === false && Object.values(changedValues).every(x => x === null || x === '') === false) {
            setDisabledButton(false);
        }
    }, [isLoading, Object.values(changedValues)]);

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/tenant',
            withCredentials: true
        })
            .then(() => {
                getCredentials();
                setChangedValues({
                    gzApiKey: null,
                    gzServerAddress: null,
                    rmmApiKey: null,
                    rmmApiSecret: null,
                    serverAddress: null
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404 && error.response.data === 'Tenant is not registered') {
                        setIsOpen(true);
                        setLoading(false);
                        setTenantStatus('New Tenant');
                        setCanApiURLBeChanged(true);
                    } else if (error.response.status === 404 && error.response.data === 'Tenant not found') {
                        setTenantStatus('Reset Tenant');
                        setLoading(false);
                        setCanApiURLBeChanged(true);
                    } else if (error.response.status === 404 && error.response.data === 'Tenant is registered, but invalid credentials') {
                        setIsOpen(true);
                        getCredentials();
                        setTenantStatus('Tenant With Invalid Credentials');
                        setCanApiURLBeChanged(false);
                    } else if (error.response.status !== 401) {
                        toastNotification(error.response.data, 'error');
                    }
                }
            })
    }, []);

    useEffect(() => {
        if (document.getElementById('gzServerAddress')) {
            document.getElementById('gzServerAddress').addEventListener('change', e => handleGzServerAddressChange(e));
        }
        if (document.getElementById('gzApiKey')) {
            document.getElementById('gzApiKey').addEventListener('change', e => handleGzApiKeyChange(e));
        }
        if (document.getElementById('rmmApiKey')) {
            document.getElementById('rmmApiKey').addEventListener('change', e => handleRmmApiKeyChange(e));
        }
        if (document.getElementById('rmmApiSecret')) {
            document.getElementById('rmmApiSecret').addEventListener('change', e => handleRmmApiSecretChange(e));
        }
        if (document.getElementById('serverAddress')) {
            document.getElementById('serverAddress').addEventListener('change', e => handleServerAddressChange(e));
        }
    }, [loading]);

    useEventListener('click', e => saveData(e), document.getElementById('save-settings-btn'));

    return (
        <div>
            <div className='p-4'>
                <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>Integration</gz-title>
                {loading ?
                <div className='center-div'>
                    <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
                </div> :
                <div className='mt-5'>
                    <gz-title fontsize="18" fontweight="500">Manage GravityZone Credentials</gz-title>
                    <div className='mt-3 mb-5'>
                        <gz-form-field label="GravityZone Access URL" id="gzServerAddressField" {...(!canApiURLBeChanged ? { readonly: true } : {})}>
                            <gz-input
                                gz-form-input
                                {...(!canApiURLBeChanged ? { readonly: true } : {})}
                                type="text"
                                errortooltiptext="Invalid GravityZone access URL."
                                maxchars="100"
                                value={gzServerAddress}
                                inputwidth="400"
                                id='gzServerAddress'>
                            </gz-input>
                        </gz-form-field>
                        <gz-form-field label="GravityZone API Key" id="gzApiKeyField">
                            <gz-input
                                gz-form-input
                                type="text"
                                errortooltiptext="Invalid GravityZone API Key"
                                maxchars="100"
                                value={gzApiKey}
                                inputwidth="400"
                                id='gzApiKey'>
                            </gz-input>
                        </gz-form-field>
                    </div>

                    <gz-title fontsize="18" fontweight="500">Manage Datto RMM Credentials</gz-title>
                    <div className='mt-3 mb-5'>
                        <gz-form-field label="Datto RMM API URL" id="rmmServerAddressField" {...(!canApiURLBeChanged ? { readonly: true } : {})}>
                            <gz-input
                                gz-form-input
                                {...(!canApiURLBeChanged ? { readonly: true } : {})}
                                type="text"
                                errortooltiptext="Invalid Datto RMM API URL"
                                maxchars="100"
                                value={serverAddress}
                                inputwidth="400"
                                id='serverAddress'>
                            </gz-input>
                        </gz-form-field>
                        <gz-form-field label="Datto RMM API Key" id="rmmApiKeyField">
                            <gz-input
                                gz-form-input
                                type="text"
                                errortooltiptext="Invalid Datto RMM API Key"
                                maxchars="100"
                                value={rmmApiKey}
                                inputwidth="400"
                                id='rmmApiKey'>
                            </gz-input>
                        </gz-form-field>
                        <gz-form-field label="Datto RMM API Secret Key" id="rmmApiSecretField">
                            <gz-input
                                gz-form-input
                                type="password"
                                errortooltiptext="Invalid Datto RMM API Secret Key"
                                maxchars="100"
                                value={rmmApiSecret}
                                inputwidth="400"
                                id='rmmApiSecret'>
                            </gz-input>
                        </gz-form-field>
                    </div>
                </div>}
            </div>

            <div className='page-footer fixed-bottom-navbar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-3 navbar-light nav-bottom-shadow py-3' style={{ visibility: loading === true ? 'hidden' : 'visible' }}>
                        <gz-button
                            type="full"
                            color="primary"
                            buttonheight="medium"
                            buttonfontsize="medium"
                            id='save-settings-btn'
                            {...(disabledButton === true) ? { disabled: true } : {}}>
                            SAVE CHANGES
                        </gz-button>
                        </div>
                        <div className='col-sm-7 nav-bottom-shadow py-2' >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsTab;
