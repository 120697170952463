export const DROPDOWN_INVENTORY_DROPDOWN = [
    {
        label: 'Manual',
        value: false
    },
    {
        label: 'Automatic',
        value: true
    }
];

export const PREFIX_CREATION_DROPDOWN = [
    {
        label: 'Always',
        value: 0
    },
    {
        label: 'Only if the name already exists',
        value: 1
    }
];
