import { React, useState, useEffect, useContext, useRef } from 'react';
import { DROPDOWN_INVENTORY_DROPDOWN, PREFIX_CREATION_DROPDOWN } from '../utils/constants.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEventListener } from '../utils/customHooks';
import authContext from '../utils/authContext';
import { GzFormField, GzCheckbox } from '../components';

function InventorySync() {
  const [synchronizeInventoryMode, setSynchronizeInventoryMode] = useState(2);
  const [destinationMovedAuto, setDestinationMovedAuto] = useState(false);
  const [destinationDeletedAuto, setDestinationDeletedAuto] = useState(false);
  const [missingAssociationFoundAuto, setMissingAssociationFoundAuto] = useState(false);
  const [eligibleForUpgradeAuto, setEligibleForUpgradeAuto] = useState(false);
  const [usePrefixForTakeover, setUsePrefixForTakeover] = useState(false);
  const [usePrefixForGenesis, setUsePrefixForGenesis] = useState(false);
  const [usePrefixForGenesisMode, setUsePrefixForGenesisMode] = useState(0);
  const [prefix, setPrefix] = useState(null);
  const [loading, setLoading] = useState(true); //screen loading
  const [buttonLoading, setButtonLoading] = useState(false); //button loading
  const [errors, setErrors] = useState([]);
  const [syncData, setSyncData] = useState({});
  const [disablePrefixCheckbox, setDisablePrefixCheckbox] = useState(true);
  const [disablePrefixForGenesisCheckbox, setDisablePrefixForGenesisCheckbox] = useState(true);

  let synchronizeInventoryModeRef = useRef(null);
  let destinationMovedAutoRef = useRef(null);
  let destinationDeletedAutoRef = useRef(null);
  let missingAssociationFoundAutoRef = useRef(null);
  let eligibleForUpgradeAutoRef = useRef(null);
  let usePrefixForTakeoverRef = useRef(null);
  let usePrefixForGenesisRef = useRef(null);
  let usePrefixForGenesisModeRef = useRef(null);
  let prefixRef = useRef(null);
  let buttonInventoryRef = useRef(null);

  const { authenticated, setAuthenticated } = useContext(authContext);
  const navigate = useNavigate();

  const toastNotification = (message, severity) => {
    const toastNotificationConfigs = {
      message: message,
      severity: severity,
      x: 'right',
      y: 'bottom',
    };

    window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
  }

  const handleRadioGroup = (e) => {
    setSynchronizeInventoryMode(e.detail.selectedValue);
  };

  const handlePrefixChange = (e) => {
    setPrefix(e.target.value);
  }

  const handleCheckboxChange = (e) => {
    switch (e.target.id) {
      case 'usePrefixForTakeover':
        setUsePrefixForTakeover(e.detail.checked);
        break;
      case 'usePrefixForGenesis':
        setUsePrefixForGenesis(e.detail.checked);
        break;

      default:
        setErrors([...errors, 'There is an error!'])
    }
  }

  const handleSelectChange = (e) => {
    switch (e.target.id) {
      case 'destinationMoved':
        setDestinationMovedAuto(e.detail.selectedValue);
        break;

      case 'destinationDeleted':
        setDestinationDeletedAuto(e.detail.selectedValue);
        break;

      case 'associationMissing':
        setMissingAssociationFoundAuto(e.detail.selectedValue);
        break;

      case 'prefixBasedAssociationMissing':
        setEligibleForUpgradeAuto(e.detail.selectedValue);
        break;

      case 'usePrefixForGenesisMode':
        setUsePrefixForGenesisMode(e.detail.selectedValue);
        break;

      default:
        setErrors([...errors, 'There is an error!'])
    }
  }

  const getInventorySyncData = async () => {
    setLoading(true);

    try {
      const response = await axios({
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
        withCredentials: true
      });
      setSyncData(response.data.synchronizeInventorySettings)

      setSynchronizeInventoryMode(response.data.synchronizeInventorySettings.synchronizeInventoryMode);
      setDestinationMovedAuto(response.data.synchronizeInventorySettings.destinationMovedAuto);
      setDestinationDeletedAuto(response.data.synchronizeInventorySettings.destinationDeletedAuto);
      setMissingAssociationFoundAuto(response.data.synchronizeInventorySettings.missingAssociationFoundAuto);
      setEligibleForUpgradeAuto(response.data.synchronizeInventorySettings.eligibleForUpgradeAuto);
      setUsePrefixForTakeover(response.data.synchronizeInventorySettings.usePrefixForTakeover);
      setUsePrefixForGenesis(response.data.synchronizeInventorySettings.usePrefixForGenesis);
      setUsePrefixForGenesisMode(response.data.synchronizeInventorySettings.usePrefixForGenesisMode);
      setPrefix(response.data.synchronizeInventorySettings.prefix);
    } catch (error) {
      setErrors({ receiveDataError: error.response.data });
    } finally {
      setLoading(false);
    }
  }

  const isNotValidData = () => {
    let isNotValidForm = true;

    isNotValidForm = destinationMovedAuto === null
      || destinationDeletedAuto === null
      || missingAssociationFoundAuto === null
      || eligibleForUpgradeAuto === null
      || usePrefixForTakeover === null
      || usePrefixForGenesis === null
      || usePrefixForGenesisMode === null;

    if (destinationMovedAuto === null) {
      destinationMovedAutoRef.current.markAsInvalid();
    }
    if (destinationDeletedAuto === null) {
      destinationDeletedAutoRef.current.markAsInvalid();
    }
    if (missingAssociationFoundAuto === null) {
      missingAssociationFoundAutoRef.current.markAsInvalid();
    }
    if (eligibleForUpgradeAuto === null) {
      eligibleForUpgradeAutoRef.current.markAsInvalid();
    }
    if (usePrefixForGenesisMode === null) {
      usePrefixForGenesisModeRef.current.markAsInvalid();
    }

    if (isNotValidForm) {
      return true;
    }
    return false;
  }

  const saveInventorySyncInfo = (e) => {
    e.preventDefault();
    setButtonLoading(true);

    if (!isNotValidData()) {

      var requestBody = {
        "synchronizeInventoryMode": parseInt(synchronizeInventoryMode),
        "destinationMovedAuto": destinationMovedAuto,
        "destinationDeletedAuto": destinationDeletedAuto,
        "missingAssociationFoundAuto": missingAssociationFoundAuto,
        "eligibleForUpgradeAuto": eligibleForUpgradeAuto,
        "sourceGoneSuperNovaAuto": syncData.sourceGoneSuperNovaAuto,
        "usePrefixForTakeover": usePrefixForTakeover,
        "usePrefixForGenesis": usePrefixForGenesis,
        "usePrefixForGenesisMode": usePrefixForGenesisMode,
        "isSyncReversed": syncData.isSyncReversed,
        "prefix": prefix,
        "createGroups": syncData.createGroups,
        "featuresSyncMode": syncData.featuresSyncMode,
        "packageConfigurationLevel": syncData.packageConfigurationLevel,
        "companyPackageNameTemplate": syncData.companyPackageNameTemplate,
        "groupPackageNameTemplate": syncData.groupPackageNameTemplate
      };

      axios({
        method: 'put',
        url: process.env.REACT_APP_API_URL + '/api/tenant/settings/inventory',
        data: requestBody,
        withCredentials: true
      })
        .then(() => {
          if (!authenticated) {
            toastNotification('Changes have been saved.', 'success');
            setAuthenticated(true);
            navigate('/companies');
          } else {
            toastNotification('Changes have been saved.', 'success');
          }
          setButtonLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500 || error.response.status === 409) {
              toastNotification('The server encountered an unexpected error.', 'error');
            } else {
              toastNotification(error.response.data, 'error');
            }
            setButtonLoading(false);
          }
        });
    }
    else {
      setButtonLoading(false);
    }
  }

  const disableInitialElements = (prefixValue) => {
    if (!prefixValue || prefixValue === '') {
      usePrefixForTakeoverRef.current.disabled = true;
      usePrefixForTakeoverRef.current.checked = false;
      usePrefixForGenesisRef.current.disabled = true;
      usePrefixForGenesisRef.current.checked = false;
      usePrefixForGenesisModeRef.current.disabled = true;
      return;
    }
    if (!usePrefixForGenesis || disablePrefixCheckbox) {
      usePrefixForGenesisModeRef.current.disabled = true;
    }
  }

  useEffect(() => {
    setDisablePrefixCheckbox(!prefix || prefix === "");
    disableInitialElements(prefix);
  }, [prefix]);

  useEffect(() => {
    setDisablePrefixForGenesisCheckbox(!usePrefixForGenesis || disablePrefixCheckbox);
  }, [usePrefixForGenesis]);

  useEffect(() => {
    setDisablePrefixForGenesisCheckbox(!usePrefixForGenesis || disablePrefixCheckbox);
  }, [disablePrefixCheckbox]);

  useEffect(() => {
    getInventorySyncData();
  }, []);

  useEffect(() => {
    const element = synchronizeInventoryModeRef.current;
    element.addEventListener('radioSelectionChanged', handleRadioGroup);

    return () => {
      element.removeEventListener('radioSelectionChanged', handleRadioGroup);
    };
  }, []);

  useEffect(() => {
    const element = prefixRef.current;
    element.addEventListener('change', handlePrefixChange);

    return () => {
      element.removeEventListener('change', handlePrefixChange);
    };
  }, []);

  useEffect(() => {
    const element = usePrefixForTakeoverRef.current;
    element.addEventListener('change', handleCheckboxChange);

    return () => {
      element.removeEventListener('change', handleCheckboxChange);
    };
  }, []);

  useEffect(() => {
    const element = usePrefixForGenesisRef.current;
    element.addEventListener('change', handleCheckboxChange);

    return () => {
      element.removeEventListener('change', handleCheckboxChange);
    };
  }, []);

  useEffect(() => {
    const element = usePrefixForGenesisModeRef.current;
    element.addEventListener('selectionChange', handleSelectChange);

    return () => {
      element.removeEventListener('selectionChange', handleSelectChange);
    };
  }, []);

  useEffect(() => {
    const element = destinationMovedAutoRef.current;
    element.addEventListener('selectionChange', handleSelectChange);

    return () => {
      element.removeEventListener('selectionChange', handleSelectChange);
    };
  }, []);

  useEffect(() => {
    const element = destinationDeletedAutoRef.current;
    element.addEventListener('selectionChange', handleSelectChange);

    return () => {
      element.removeEventListener('selectionChange', handleSelectChange);
    };
  }, []);

  useEffect(() => {
    const element = missingAssociationFoundAutoRef.current;
    element.addEventListener('selectionChange', handleSelectChange);

    return () => {
      element.removeEventListener('selectionChange', handleSelectChange);
    };
  }, []);

  useEffect(() => {
    const element = eligibleForUpgradeAutoRef.current;
    element.addEventListener('selectionChange', handleSelectChange);

    return () => {
      element.removeEventListener('selectionChange', handleSelectChange);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      disableInitialElements(prefixRef.value);
    } else {
      missingAssociationFoundAutoRef.current.value = missingAssociationFoundAuto ? true : false;
      eligibleForUpgradeAutoRef.current.value = eligibleForUpgradeAuto ? true : false;
      destinationDeletedAutoRef.current.value = destinationDeletedAuto ? true : false;
      destinationMovedAutoRef.current.value = destinationMovedAuto ? true : false;
      usePrefixForGenesisModeRef.current.value = usePrefixForGenesisMode;
    }
  }, [loading]);

  useEventListener('click', saveInventorySyncInfo, document.getElementById('save-inventory-btn'));

  return (
    <div style={{ overflowY: 'scroll', maxHeight: '90vh' }}>
      <div className='p-4 mb-3'>
        <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>Inventory Sync</gz-title>
        <div className='center-div' style={{ visibility: loading === true ? 'visible' : 'hidden' }}>
          <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
        </div>
        <div style={{ visibility: loading === true ? 'hidden' : 'visible' }}>

          <gz-text fontsize="13" lineheight="20" class="mt-2 mb-4">Configure inventory synchronization between Datto RMM and GravityZone.&nbsp;
            <gz-link fontsize="13" href="https://www.bitdefender.com/business/support/en/77211-321687-setting-up-datto-rmm-integration.html" type="quiet" color="primary">
              Learn more.
            </gz-link></gz-text>

          <gz-title fontsize="18" fontweight="500">Synchronization level</gz-title>
          <gz-radio-group
            gz-form-input=""
            errortooltiptext=""
            selectedoption={synchronizeInventoryMode}
            id="syncLevelGroup"
            class="mt-3 mb-4"
            ref={synchronizeInventoryModeRef}
          >
            <gz-radio value={0} label="Monitor & report synchronized inventory status" name="radio-group-1"></gz-radio>
            <gz-text fontweight="400" fontsize="12" style={{ paddingLeft: '1.7rem' }} class='mb-3'>Generates events for already synchronized inventory.</gz-text>

            <gz-radio value={2} label="Monitor & handle synchronized inventory status" name="radio-group-2" checked=""></gz-radio>
            <gz-text fontweight="400" fontsize="12" style={{ paddingLeft: '1.7rem' }} class='mb-3'>Generates and handles events for already synchronized inventory.</gz-text>

            <gz-radio value={1} label="Automatic inventory synchronization" name="radio-group-3"></gz-radio>
            <gz-text fontweight="400" fontsize="12" style={{ paddingLeft: '1.7rem' }} class='mb-3'>Generates and handles sync events for the entire inventory. To skip individual Datto sites, configure exclusions on the Companies page.</gz-text>
          </gz-radio-group>

          <gz-title fontsize="18" fontweight="500">Synchronization event handling</gz-title>
          <div className='mt-3 mb-4'>
            <GzFormField
              ref={destinationMovedAutoRef}
              label="Destination moved"
              formFieldId="destinationMovedField"
              id="destinationMoved"
              placeholder="Select value"
              filterOptions={DROPDOWN_INVENTORY_DROPDOWN}>
            </GzFormField>

            <GzFormField
              ref={destinationDeletedAutoRef}
              label="Destination deleted"
              formFieldId="destinationDeletedField"
              id="destinationDeleted"
              placeholder="Select value"
              filterOptions={DROPDOWN_INVENTORY_DROPDOWN}>
            </GzFormField>

            <GzFormField
              ref={missingAssociationFoundAutoRef}
              label="Association missing"
              formFieldId="associationMissingField"
              id="associationMissing"
              placeholder="Select value"
              filterOptions={DROPDOWN_INVENTORY_DROPDOWN}>
            </GzFormField>

            <GzFormField
              ref={eligibleForUpgradeAutoRef}
              label="Prefix-based association missing"
              formFieldId="prefixBasedAssociationMissingLabel"
              id="prefixBasedAssociationMissing"
              placeholder="Select value"
              filterOptions={DROPDOWN_INVENTORY_DROPDOWN}>
            </GzFormField>
          </div>

          <div style={{ minHeight: '250px' }}>
            <gz-title fontsize="18" fontweight="500">Prefix settings</gz-title>
            <gz-form-field label="Prefix (used before company name)" id="prefix">
              <gz-input
                ref={prefixRef}
                gz-form-input=""
                type="text"
                placeholder="Type prefix here"
                inputwidth="160"
                id="prefixInput"
                value={prefix}>
              </gz-input>
            </gz-form-field>

            <div className='d-flex flex-row'>
              <div className='me-5'>
                <gz-checkbox
                  id="usePrefixForGenesis"
                  ref={usePrefixForGenesisRef}
                  gz-form-input
                  color='primary'
                  label="Use prefix for creation"
                  { ...(disablePrefixCheckbox === true ? {disabled: true} : {})}
                  { ...(usePrefixForGenesis === true ? {checked: true} : {})}
                />
              </div>

              <div style={{ marginLeft: '2rem' }}>
                <gz-form-field label="" id="usePrefixForGenesisModeLabel" required>
                  <gz-single-select
                    id="usePrefixForGenesisMode"
                    ref={usePrefixForGenesisModeRef}
                    gz-form-input
                    labelWidth="large"
                    placeholder="Select value"
                    errortooltiptext="Invalid value"
                    required
                    filterOptions={JSON.stringify(PREFIX_CREATION_DROPDOWN)}
                    {...(disablePrefixForGenesisCheckbox === true ? { disabled: true } : {})}>
                  </gz-single-select>
                </gz-form-field>
              </div>
            </div>

            <gz-checkbox
              id="usePrefixForTakeover"
              ref={usePrefixForTakeoverRef}
              gz-form-input
              color='primary'
              label="Use prefix for association"
              { ...(disablePrefixCheckbox === true ? {disabled: true} : {})}
              { ...(usePrefixForTakeover === true ? {checked: true} : {})}
            />
          </div>

        </div>
      </div>

      <div className='page-footer fixed-bottom-navbar'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-3 navbar-light nav-bottom-shadow py-0' style={{ visibility: loading === true ? 'hidden' : 'visible' }}>
              <gz-button
                ref={buttonInventoryRef}
                type="full"
                color="primary"
                buttonheight="medium"
                buttonfontsize="medium"
                id='save-inventory-btn'
                class='py-3'
                {...buttonLoading ? { disabled: true } : {}}>
                SAVE CHANGES
              </gz-button>
            </div>
            <div className='col-sm-7 nav-bottom-shadow py-2' >
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default InventorySync;